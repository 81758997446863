export default class ModalDialog {

    constructor(modalDialogId, notification) {
        this.modalDialogId = notification ?  'notification' : modalDialogId
        this.notification = notification

        this.openButtonsArray = Array.from(document.querySelectorAll(`.js-open-modal-${this.modalDialogId}`))

        this.modalDialog = document.querySelector(`#modal-${this.modalDialogId}`)
        this.closeButtonsArray = null
        this.modalContent = this.modalDialog.querySelector('.js-modal-content')

        this.init()
    };

    init() {
        if (this.openButtonsArray.length) {
            this.openButtonsArray.forEach((item) => {
                item.addEventListener('click', () => {
                    this.openModalDialog()
                })
            })
        }

        if (this.notification) {
            this.modalContent.innerHTML = this.notification.html
        }

        this.closeButtonsArray = Array.from(this.modalDialog.querySelectorAll('.js-modal-dialog-close'))
    }

    openModalDialog() {
        this.modalDialog.classList.add('opened')
        document.body.classList.add('overflow-hidden')

        this.closeButtonsArray.forEach((closeBtn) => {
            closeBtn.addEventListener('click', () => {
                this.closeModalDialog()
            })
        })
    }

    closeModalDialog() {
        this.modalDialog.classList.remove('opened')
        document.body.classList.remove('overflow-hidden')

        this.closeButtonsArray.forEach((closeBtn) => {
            closeBtn.removeEventListener('click', this.closeModalDialog)
        })
    }
}