import axios from "axios";
import JustValidate from "just-validate";
import ModalDialog from './modal-dialog';

const feedbackForm = () => {
    const form = document.querySelector('#feedback-form')
    const btnSubmit = document.querySelector('.js-btn-submit-feedback')

    const validation = new JustValidate("#feedback-form", {
        errorFieldCssClass: "error",
    });

    validation
        .addField("#feedback_email", [
            {
                rule: "required",
                errorMessage: "Необходимо ввести Email.",
            },
            {
                rule: "email",
                errorMessage: "Неверный формат Email.",
            },
        ])
        .addField("#feedback_name", [
            {
                rule: "required",
                errorMessage: "Необходимо ввести Имя.",
            }
        ])
        .addField("#feedback_subject", [
            {
                rule: "required",
                errorMessage: "Необходимо ввести Тему.",
            }
        ])
        .addField("#feedback_message", [
            {
                rule: "required",
                errorMessage: "Необходимо ввести Сообщение.",
            }
        ])
        .onSuccess((e) => {
            let formData = new FormData(form);

            btnSubmit.disabled = true

            axios.post(`${import.meta.env.EXPKG_API_URL}/feedback`, {
                name: formData.get('feedback_name'),
                email: formData.get('feedback_email'),
                theme: formData.get('feedback_subject'),
                message: formData.get('feedback_message')
            })
            .then((res) => {
                let modal = new ModalDialog(null, {
                    html: createMarkup('Спасибо!', 'Ваше сообщение отправлено.', 'OK', 'green')
                })

                btnSubmit.disabled = false
                modal.openModalDialog()
            })
            .catch((error) => {
                let modal = new ModalDialog(null, {
                    html: createMarkup('Ошибка', 'Ошибка при отправке данных, повторите попытку позже.', 'Закрыть', 'red')
                })

                btnSubmit.disabled = true
                modal.openModalDialog()
            });
        })

    const createMarkup = (title, message, btnText, btnColor) => {
        let markup =`
        <div class="modal-dialog__title">${title}</div>
        <div class="modal-dialog__body">
            <p>${ message }</p>
        </div>
        <div class="modal-dialog__footer">
            <div class="modal-dialog__btn-container">
                <button type="button" class="btn btn-${ btnColor } js-modal-dialog-close">${ btnText }</button>
            </div>
        </div>`

        return markup
    }
}

export default feedbackForm;