import "./style.scss";

import feedbackForm from './js/blocks/feedback-form';
import mainPage from './js/blocks/main-page';
import search from './js/blocks/search';
import expertise from './js/blocks/expertise';

document.addEventListener("DOMContentLoaded", async () => {
    feedbackForm()
    mainPage()
    search()
    expertise()

    let navToggle = document.querySelector('.js-nav-toggle')

    navToggle.addEventListener('click', (e) => {
        navToggle.nextElementSibling.classList.toggle('opened')
    })
})
