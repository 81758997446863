import axios from "axios";
import JustValidate from "just-validate";
import ModalDialog from './modal-dialog';

const feedbackForm = () => {
    const form = document.querySelector('#expertise-order-form')
    const btnSubmit = document.querySelector('.js-btn-submit-expertise')
    const validation = new JustValidate("#expertise-order-form", {
        errorFieldCssClass: "error",
    });

    const expertiseModal = new ModalDialog('order-expertise');

    const init = () => {
        getExpTypes()
    }

    validation
        .addField("#order-name", [
            {
                rule: "required"
            }
        ])
        .addField("#order-tel", [
            {
                rule: "required"
            }
        ])
        .addField("#order-email", [
            {
                rule: "required"
            },
            {
                rule: "email"
            }
        ])
        .addField("#order-city", [
            {
                rule: "required"
            }
        ])
        .addField("#order-type", [
            {
                rule: "required"
            }
        ])
        .onSuccess((e) => {
            btnSubmit.disabled = true
            
            let formData = new FormData(form);
            let reportForm = []

            if (formData.get('order-verbal-report') === 'on') {
                reportForm.push('устная консультация')
            }
            
            if (formData.get('order-written-report') === 'on') {
                reportForm.push('письменная консультация')
            }

            let captchaResponse = grecaptcha.getResponse()

            if (captchaResponse.length > 0) {
                axios.post(`${import.meta.env.EXPKG_API_URL}/send-expertise-request`, {
                    name: formData.get('order-name'),
                    email: formData.get('order-email'),
                    phone: formData.get('order-tel'),
                    city: formData.get('order-city'),
                    type_name: formData.get('order-type'),
                    form: reportForm,
                    comment: formData.get('order-comment')
                })
                .then((res) => {
                    let modal = new ModalDialog(null, {
                        html: createMarkup('Ваша заявка отправлена.', 'Наш специалист свяжется с Вами в ближайшее время.', 'OK', 'green')
                    })
    
                    modal.openModalDialog()
                    form.reset()
                    expertiseModal.closeModalDialog()
                    btnSubmit.disabled = false
                })
                .catch((error) => {
                    let modal = new ModalDialog(null, {
                        html: createMarkup('Ошибка', 'Ошибка при отправке данных, повторите попытку позже.', 'Закрыть', 'red')
                    })
    
                    modal.openModalDialog()
                    btnSubmit.disabled = false
                })
            } else {
                let modal = new ModalDialog(null, {
                    html: createMarkup('Ошибка', 'Необходимо пройти проверку.', 'Закрыть', 'red')
                })

                modal.openModalDialog()
            }
        })

    const getExpTypes = () => {
        axios({
            method: "get",
            url: `${import.meta.env.EXPKG_API_URL}/types`,
            
        })
        .then((response) => {
            renderTypesSelect(response.data)
        })
    }

    const createMarkup = (title, message, btnText, btnColor) => {
        let markup =`
        <div class="modal-dialog__title">${title}</div>
        <div class="modal-dialog__body">
            <p>${ message }</p>
        </div>
        <div class="modal-dialog__footer">
            <div class="modal-dialog__btn-container">
                <button type="button" class="btn btn-${ btnColor } js-modal-dialog-close">${ btnText }</button>
            </div>
        </div>`

        return markup
    }

    const renderTypesSelect = (data) => {
        let typesSelect = document.querySelector('#order-type')

        data.forEach(type => {
            let el = document.createElement('option')

            el.innerHTML = type.name
            el.value = type.name

            typesSelect.append(el)
        });
    }

    init()
}

export default feedbackForm;