import axios from "axios";
import ModalDialog from './modal-dialog';

const mainPage = () => {
    const mainNav = document.querySelector("#main-nav")
    const mainContent = document.querySelector("#main-page-content")

    const init = () => {
        getData()
    }
    
    const getData = () => {
        axios({
            method: "get",
            url: `${import.meta.env.EXPKG_API_URL}/getInfo`,
            
        })
        .then((response) => {
            renderMainNav(response.data.data)
            renderContent(response.data.data)
        })
    }

    const renderMainNav = (data) => {
        data.reverse().forEach(item => {
            if (item.activation == 1) {
                const li = document.createElement('li')
                const a = document.createElement('a')
                
                a.classList.add('nav-link')
                a.href = `#section-${item.id}`
                a.innerHTML = item.title
    
                li.classList.add('nav-item')
                li.append(a)
                
                mainNav.prepend(li)
            }
        });
    }

    const renderContent = (data, callback) => {
        const domParser = new DOMParser();

        data.reverse().forEach(item => {
            if (item.activation == 1) {
                const domString = `
                    <section class="page-section" id="section-${item.id}">
                        <h2 class="section-title">${item.title}</h2>
                        <div class="page-section__content">
                            ${item.content}
                        </div>
                    </section>
                `
                
                const domContent = domParser.parseFromString(domString, 'text/html');
                const contentEl = domContent.body.firstChild

                if (item.show_expertise_button) {
                    contentEl.classList.add('.has-button')
                }
    
                if (item.show_expertise_button) {
                    const pageSectionContent = contentEl.querySelector('.page-section__content')
                    const domButton = domParser.parseFromString('<button type="button" class="btn btn-green js-open-modal-order-expertise" data-modal-dialog-open="order-modal-dialog">Заказать экспертизу</button>', 'text/html');
    
                    pageSectionContent.append(domButton.body.firstChild)
                    contentEl.classList.add('has-button')
                }
                
                mainContent.append(domContent.body.firstChild)
            }
        });

        new ModalDialog('order-expertise')
    }

    init()
}

export default mainPage;