import axios from "axios";
import ModalDialog from './modal-dialog';

const search = () => {
    const form = document.querySelector('#expertise-search-form')

    form.addEventListener('submit', (e) => {
        e.preventDefault();

        let formData = new FormData(form);
        let expertiseNumber = formData.get('number_of_expertise')
        axios({
            method: 'get',
            url: `${import.meta.env.EXPKG_API_URL}/search/${parseInt(expertiseNumber)}`,
            
        })
        .then((response) => {
            handleModal(response.data)
            
        })
        .catch((error) => {
            let modalText = error.response.status === 404 ? 'Документа с таким номером не существует.' : `Ошибка ${error.response.status}. Попробуйте поискать позже.`
            let modal = new ModalDialog(null, {
                html: createErrorModal('Ошибка', modalText)
            })

            modal.openModalDialog()
        })
    })

    const handleModal = (data) => {
        let modal = null;

        if (data.cancellation_date) {
            modal = new ModalDialog(null, {
                html: createCancelledModal(data)
            })
            modal.openModalDialog()
            
            return
        }

        if (data.pdf) {
            modal = new ModalDialog(null, {
                html: createReadyModal(data)
            })
        } else {
            modal = new ModalDialog(null, {
                html: createErrorModal('Ошибка', 'Документа с таким номером не существует.')
            })
        }

        modal.openModalDialog()
    }

    const createErrorModal = (title, message, buttons) => {
        let markup =`
        <div class="modal-dialog__title">${title}</div>
        <div class="modal-dialog__body">
            <p>${ message }</p>
        </div>
        <div class="modal-dialog__footer">
            <div class="modal-dialog__btn-container">
                <button type="button" class="btn btn-red js-modal-dialog-close">Закрыть</button>
            </div>
        </div>`

        return markup
    }

    const createCancelledModal = (data) => {
        let markup =`
        <div class="modal-dialog__title">Ошибка</div>
        <div class="modal-dialog__body">
            <p>
                Заключение № <span class="text-numeric">${ data.number }</span> аннулировано <span class="text-numeric">${ data.cancellation_date }</span> г.<br>
                Причина: ${ data.reason }
            </p>
        </div>
        <div class="modal-dialog__footer">
            <div class="modal-dialog__btn-container">
                <button type="button" class="btn btn-red js-modal-dialog-close">Закрыть</button>
            </div>
        </div>`

        return markup
    }

    const createReadyModal = (data) => {
        let markup =`
        <div class="modal-dialog__title">Скачать PDF</div>
        <div class="modal-dialog__body">
            <p>
                Заключение № <span class="text-numeric">${ data.number }</span> готово к скачиванию.
            </p>
        </div>
        <div class="modal-dialog__footer">
            <div class="modal-dialog__btn-container">
                <button type="button" class="btn btn-red js-modal-dialog-close">Отмена</button>
                <a href="${ data.pdf }" target="_blank" class="btn btn-green">Скачать</a>
            </div>
        </div>`

        return markup
    }
}

export default search;